<template>
  <div class="register">
    <div class="title">报名</div>
    <div class="container">
      <el-form :model="form" ref="ruleForm" label-width="50px">
        <el-form-item label="学校">
          <el-input v-model="form.schoolName" clearable></el-input>
        </el-form-item>
        <el-form-item label="姓名">
          <el-input v-model="form.name" clearable></el-input>
        </el-form-item>
        <el-form-item label="科目">
          <el-select v-model="form.subject" style="width: 100%">
            <el-option label="数学" value="数学"></el-option>
            <el-option label="物理" value="物理"></el-option>
            <el-option label="化学" value="化学"></el-option>
            <el-option label="英语" value="英语"></el-option>
            <el-option label="语文" value="语文"></el-option>
            <el-option label="生物" value="生物"></el-option>
            <el-option label="历史" value="历史"></el-option>
            <el-option label="地理" value="地理"></el-option>
            <el-option label="科学" value="科学"></el-option>
            <el-option label="道德与法治" value="道德与法治"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="年级">
          <el-select v-model="form.grade" style="width: 100%">
            <el-option label="一年级" value="一年级"></el-option>
            <el-option label="二年级" value="二年级"></el-option>
            <el-option label="三年级" value="三年级"></el-option>
            <el-option label="四年级" value="四年级"></el-option>
            <el-option label="五年级" value="五年级"></el-option>
            <el-option label="六年级" value="六年级"></el-option>
            <el-option label="七年级" value="七年级"></el-option>
            <el-option label="八年级" value="八年级"></el-option>
            <el-option label="九年级" value="九年级"></el-option>
            <el-option label="高一" value="高一"></el-option>
            <el-option label="高二" value="高二"></el-option>
            <el-option label="高三" value="高三"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="性别">
          <el-select v-model="form.gender" style="width: 100%">
            <el-option label="男" :value="1"></el-option>
            <el-option label="女" :value="0"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="年龄">
          <el-input v-model="form.age" clearable></el-input>
        </el-form-item>
        <el-form-item label="电话">
          <el-input v-model="form.phone" type="number" :maxlength="11" clearable></el-input>
        </el-form-item>
        <el-form-item label="描述">
          <el-input v-model="form.description" type="textarea" :rows="3" clearable></el-input>
        </el-form-item>
      </el-form>
      <el-button type="primary" style="width: 100%" :disabled="finish" :loading="loading" @click="submit">{{ finish ? '已提交' : '提交信息' }}</el-button>
    </div>
  </div>
</template>

<script>
import request from '../../request'
import { Input, Select, Option, Form, FormItem, Button, Message } from 'element-ui'
export default {
  name: 'register',
  components: {
    'el-select': Select,
    'el-option': Option,
    'el-button': Button,
    'el-input': Input,
    'el-form': Form,
    'el-form-item': FormItem
  },
  data() {
    return {
      form: {
        schoolName: '',
        name: '',
        subject: '',
        grade: '',
        gender: '',
        age: '',
        phone: '',
        description: ''
      },
      loading: false,
      finish: false
    }
  },
  created() {
    document.title = '智能学习报名表'
  },
  methods: {
    submit() {
      this.loading = true
      request({
        url: '/api/guest/school/project/add',
        method: 'post',
        data: this.form
      }).then(() => {
        Message.success({
          message: '提交信息成功',
          offset: 80
        })
        this.finish = true
      }).catch(() => {
        Message.warning('提交失败，请稍后再试')
      }).finally(() => { this.loading = false })
    }
  }
}
</script>

<style>
.el-message{
  min-width: 150px;
}
</style>

<style lang="scss" scoped>
.register{
  .title{
    margin: 20px auto;
    text-align: center;
    font-size: 18px;
  }
  .container{
    width: 90%;
    margin: 0 auto;
  }
}
</style>
